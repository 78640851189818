
import lang from "@/mixins/lang.js";

const addGoogleQueryParamsToURL = (_url, _queryParams) => {
  const urlObj = new URL(_url);
  const existingSearchParams = new URLSearchParams(urlObj.search);
  const allSearchParams = new URLSearchParams();
  // Add google query parameter from the provided object
  for (const param in _queryParams) {
    if (param === "gclid" || param === "gbraid") {
      allSearchParams.set(param, _queryParams[param]);
    }
  }

  existingSearchParams.forEach((value, key) => {
    allSearchParams.append(key, value);
  });

  // Update the search property of the URL object
  urlObj.search = allSearchParams.toString();
  return urlObj.toString();
};

export default {
  name: "Button",
  mixins: [lang],
  props: {
    label: {
      type: String,
      default: "",
    },
    titleText: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    query: {
      type: String,
      default: "",
    },
    external: {
      type: Boolean,
      default: false,
    },
    mobileUrl: {
      type: String,
      default: "",
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    withInPage: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "normal",
    },
    className: {
      type: String,
      default: "",
    },
    forcedOnClick: {
      type: Function,
      default: null,
    },
    linkArrow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedUrl() {
      if (this.isMobile && this.external) {
        return addGoogleQueryParamsToURL(this.mobileUrl, this.$route.query);
      } else {
        return this.external
          ? addGoogleQueryParamsToURL(this.url, this.$route.query)
          : this.url;
      }
    },
  },
  data() {
    return {
      externalUrl: "",
    };
  },
  mounted() {
    if (this.external) {
      this.externalUrl = addGoogleQueryParamsToURL(this.url, this.$route.query);
    }
  },
  methods: {
    handleWithInPage: function () {
      const el = document.querySelector(this.url);
      if (!el) return;
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
};
