
// import lang from "~/mixins/lang.js";

export default {
  name: "CardDefault",
  // mixins: [lang],
  props: {
    content: {
      type: Object,
      default() {},
    },
  },
  mounted() {
    //if (process.browser) {
    //  const resize = () => (this.small = window.screen.width < 900);
    //  window.addEventListener("resize", resize);
    //  resize();
    //}
  },
  data() {
    return {
      small: false,
      slickSettings: {
        draggable: true,
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 250,
        arrows: false,
        dots: false,
        rtl: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1.5,
            },
          },
          {
            breakpoint: 300,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
      slickSettingsVertical: {
        slidesToShow: 3,
        draggable: false,
        focusOnSelect: false,
        infinite: false,
        speed: 250,
        arrows: false,
        dots: false,
        rtl: false,
        responsive: [
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
